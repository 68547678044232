<template>
  <div class="help">
    <Header></Header>
    <div class="banner-help">
      <div class="banner-help-font">帮助中心</div>
    </div>
    <div class="help-center">
      <div class="qiehuan">
        <el-menu
          default-active="2"
          class="el-menu-vertical-demo"
          :router="true"
          :default-openeds="['/help/cate/1']"
          @select="menuSelectHandle"
          @open="openMenuHandle"
          @close="closeMenuHandle"
        >
          <el-menu-item index="/help">
            <i class="el-icon-s-home"></i>
            <template slot="title">帮助中心</template>
          </el-menu-item>
          <el-submenu
            v-for="(cate, index) in categorys"
            :index="'/help/cate/' + cate.id"
            :key="index"
          >
            <template slot="title">{{ cate.name }}</template>
            <el-menu-item
              v-if="cate.children.length > 0"
              :index="'/help/cate/' + cat.id"
              v-for="(cat, key) in cate.children"
              :key="key"
            >
              {{ cat.name }}
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
      <!-- 右侧的帮助 -->
      <div class="help-center-right">
        <div class="help-center-right-top">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item v-for="(ctwo, ktwo) in breadcrumbs" :key="ktwo">
              {{ ctwo.name }}
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- 下面的认证 -->
        <div class="help-center-right-attestation">
          <div class="help-center-right-attestation-top">
            <ul v-if="contents.length > 0">
              <li v-for="(content, k) in contents" :key="k" @click="toContentInfo(content)">
                <el-link> {{ content.title }} </el-link>
              </li>
            </ul>
            <el-empty v-if="contents.length == 0" description="暂无内容"></el-empty>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
  import { getContentCategorys, getContents } from '@/api/cms';
  /* import Dia from "@/views/loginRes/DIaLogin" */
  export default {
    /*  components:{
        Dia
    }, */
    name: 'help',
    data() {
      return {
        categorys: [],
        contents: [],
        catId: 0,
        breadcrumbs: []
      };
    },
    created() {
      this.loadContentCategorys();
      this.catId = this.$route.params.id;
      if (this.catId > 0) {
        this.loadContents({ catid: this.catId });
        setTimeout(() => {
          this.$nextTick(() => {
            this.buildBreadcrumbs(this.catId);
          });
        }, 1000);
      }
    },
    mounted() {
      this.$watch(
        () => this.$route.params,
        (toParams, previousParams) => {
          this.loadContents({ catid: toParams.id });
          this.buildBreadcrumbs(toParams.id);
        }
      );
    },
    methods: {
      menuSelectHandle(index, indexPath) {
        // console.log(index, indexPath, 1111111111);
      },
      closeMenuHandle(index, indexPath) {
        this.$router.push(index);
        // console.log(index, indexPath, 22222222);
      },
      openMenuHandle(index, indexPath) {
        this.$router.push(index);
        // console.log(index, indexPath, 33333333);
      },
      async loadContents(data) {
        const res = await getContents(data);
        if (res.statusCode == 200) {
          this.contents = res.data.list;
        }
      },
      async loadContentCategorys(pid = 0) {
        const res = await getContentCategorys({ pid: pid });
        if (res.statusCode == 200) {
          this.categorys = res.data;
        }
      },
      toContentInfo(content) {
        //window.open(content.url,'_blank');
        this.$router.push('/help/info/' + content.uuid);
      },
      buildBreadcrumbs(newID) {
        let data = [];
        this.categorys.forEach((Element) => {
          if (Element.children.length > 0) {
            Element.children.forEach((e) => {
              if (e.id == newID) {
                data.push({ name: Element.name });
                data.push(e);
              }
            });
          }
        });
        this.breadcrumbs = data;
      }
    }
  };
</script>

<style lang="less" scoped>
  .qiehuan {
    width: 240px;
  }
  /deep/.el-submenu__title {
    font-size: 14px;
    font-weight: bold;
  }
  /deep/.el-submenu .el-menu-item {
    height: 45px;
  }
  body {
    background-color: #f8f8f8;
  }
  .banner-help {
    width: 100%;
    background-size: cover;
    background-image: url(~@/assets/banner/底图.jpg);
    position: relative;
    text-align: center;
    .banner-help-font {
      line-height: 36px;
      color: #fff;
      font-size: 36px;
      padding: 40px 0;
    }
  }
  .help-center {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    margin-top: 20px;
    .help-center-right {
      margin-left: 20px;
      .help-center-right-top {
        width: 100%;
        padding: 10px 0;
        line-height: 36px;
        font-size: 16px;
        color: #474747;
      }
      .help-center-right-attestation {
        width: 940px;
        min-height: 300px;
        background: #ffffff;
        margin-top: 10px;
        .help-center-right-attestation-top {
          min-height: 600px;
          margin: 0 auto;
          border-bottom: 1px solid #b5b5b6;
          ul {
            padding: 0 15px;
            margin-top: 30px;
            li {
              display: block;
              margin-bottom: 18px;
            }
          }
        }
        .help-center-right-attestation-bottom {
          float: left;
          position: relative;
          top: 20px;
          left: 40px;
          font-size: 14px;
          p {
            span {
              cursor: pointer;
              color: #e4007f;
            }
          }
        }
      }
    }
  }
</style>
